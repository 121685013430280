.cars-list-container[data-v-bb869f6a] {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 0.3rem;
}
.cars-list-header[data-v-bb869f6a] {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 3.5rem;
  color: var(--color-text-primary);
  display: block;
  padding: 0.4rem 0.8rem;
  text-align: left;
}
.cars-list-header[data-v-bb869f6a]::before {
  content: attr(data-state);
}