.calendar-week-day[data-v-e8b2eeef] {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;
  border-radius: var(--radius);
  padding: 0.4rem 0.2rem;
  gap: 0.2rem;
  transition: transform var(--p-transition-duration), box-shadow var(--p-transition-duration), background-color var(--p-transition-duration), z-index var(--p-transition-duration);
  height: fit-content;
  z-index: 1;
}
.calendar-week-day-name[data-v-e8b2eeef] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  text-align: center;
  width: 100%;
}
.calendar-week-day.-focusable:hover .calendar-week-day-name[data-v-e8b2eeef] {
  z-index: inherit;
}
.calendar-week-day.-focusable .calendar-week-day-work[data-v-e8b2eeef],
.calendar-week-day.-focusable .calendar-week-day-cars[data-v-e8b2eeef] {
  pointer-events: none;
}
.calendar-week-day-work[data-v-e8b2eeef] {
  display: block;
  width: 100%;
  height: 5rem;
}
.calendar-week-day-cars[data-v-e8b2eeef] {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
}
.add-car-button[data-v-e8b2eeef] {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.4px solid var(--color-border-tertiary);
  border-radius: var(--radius);
  height: 1.6rem;
}
.add-car-button-icon[data-v-e8b2eeef] {
  color: var(--color-icon);
}
.add-car-button:hover .add-car-button-icon[data-v-e8b2eeef] {
  color: var(--color-icon-focus);
}